<template>
  <div>
    <div>
      <b-form-checkbox v-model="edit" name="check-button" switch>
        <b-icon icon="pen"></b-icon>Edit<b>(Checked: {{ edit }})</b>
      </b-form-checkbox>
      <b-row>
        <img :src="makeBanorimage(events.bannerImages)" alt="" width="100%" />
      </b-row>

      <ImagPicker
        v-if="edit"
        :imageData="{ maxNoImg: 1, imageNo: 0 }"
      ></ImagPicker>
      <b-row style="margin-top: 5%">
        <b-col style="margin-bottom: 30px" md="12">
          <h3 v-if="!edit" class="text-center">{{ events.header }}</h3>
          <b-form-textarea
            v-if="edit"
            id="textarea"
            v-model="header"
            :placeholder="events.header"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-col>

        <b-col md="8">
            <p v-if="!edit">{{ events.details }}</p>
<b-form-textarea
            v-if="edit"
            id="textarea"
            v-model="details"
            :placeholder="events.details"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-col>
        <b-col md="3">
             <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
    <div v-for="(image,index) in events.eventsImages" :key="index">
        <b-carousel-slide  :img-src="image">
        
      </b-carousel-slide>
    </div>
    
             </b-carousel>
            <ImagPicker
        v-if="edit"
        :imageData="{ maxNoImg: 10, imageNo: 1 }"
      ></ImagPicker>
        </b-col>
      </b-row>
<div class="text-center" v-if="edit">
    <b-button @click="Updateevents()" variant="success">Submit</b-button>
</div>
      
    </div>
  </div>
</template>
<script>
import ImagPicker from "../Common/ImageUpload.vue";
import Vue from 'vue';

const { imageToBanner, pathField } = require("../../assets/js/service");
export default {
  components: {
    ImagPicker,
  },
  data() {
    return {
      events: this.$store.state.singlePageData,
      edit: false,
      sliding: null,
      slide: 0,
      header: null,
      details: null,
    };
  },
  mounted() {
    this.$store.commit("set", ["images",[]]);
    this.$store.commit("set", ["images1",[]]);
    if (!this.$store.state.singlePageData) {
      this.$router.push("/events");
    }
  },
  methods: {
     onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      }, 
    makeBanorimage(img) {
      return imageToBanner("banner", img);
    },
    editevents() {
      this.edit = !edit;
    },
    Updateevents(){
      let updatedevents = {};
      let update = false;
      if(this.header){
        updatedevents.header = this.header;
        update = true;
      }
      if(this.details){
        updatedevents.details = this.details;
        update = true;
      }
      if(this.$store.state.images.length > 0){
        updatedevents.bannerImages = this.$store.state.images;
        update = true;
      }
      if(this.$store.state.images1.length > 0){
        updatedevents.eventsImages = this.$store.state.images1;
        update = true;
      }

      if(update){
        let token = localStorage.getItem("token");
        pathField('events',this.events.id,updatedevents,token).then(resp=>{
          this.events = resp.data;
          this.$store.commit("set", ["singlePageData",this.events]);
          this.header = null,
          this.details =null,
           this.$store.commit("set", ["images",[]]);
    this.$store.commit("set", ["images1",[]]);
    Vue.$toast.success("Successful", {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              
        })
      }else{

      }
    }
  },
};
</script>
<style>
</style>